import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { callApi } from '../../../../Api/ApiCaller';
import routes from '../../../../Api/routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const SignUpSuccessfull = ({ setuserlogged }) => {
  
    const [uploadAvatar, setUploadAvatar] = useState(localStorage.getItem('uploadAvatar'));
    const [selectedSport, setSelectedSport] = useState(localStorage.getItem('selectedSport'));
    const [selectedField, setselectedField] = useState(localStorage.getItem('selectedField'));
    const [isloading, setIsLoading] = useState(false);
    const [signUpDone, setsignUpDone] = useState('');
    const navigate = useNavigate();
    let userData = JSON.parse(localStorage.getItem('userData'));
    const handleBuildProfile = () => {
      
      console.log(uploadAvatar); 
      console.log(selectedSport); 
      console.log(selectedField); 
     
      
      let getRes = (res) => {
        
        if (res.status == 200) {
          
          localStorage.setItem("userData", JSON.stringify(res?.user));
          setsignUpDone('Successful')
        } else {
          setsignUpDone('Not Successful')
        }
      }; 
      let body = {
        avatar: uploadAvatar,
        sport: selectedSport,
        design: selectedField,
      
      };
      callApi("POST", routes.completeProfile, body, setIsLoading, getRes, (error) => {
        console.log("error", error);
      });
    };
    const handleUserBuildProfile = () => {
      
      console.log(uploadAvatar); 
      
      let getRes = (res) => {
        if (res.status == 200) {
          
          setsignUpDone('Successful')
        } else {
          setsignUpDone('Not Successful')
        }
      }; 
      let body = {
        avatar: uploadAvatar,
      };
      callApi("POST", routes.completeProfile, body, setIsLoading, getRes, (error) => {
        console.log("error", error);
      });
    };
    useEffect(() => {
      if(userData?.role === 'admin'){
        handleBuildProfile();
      }
      else{
        handleUserBuildProfile();
      }
    }, []);
    const handleContinue = (e) => {
      e.preventDefault();
          let userData = JSON.parse(localStorage.getItem('userData'));
          console.log(userData?.role)
          if (userData) {
            if (userData.role === 'admin') {
              localStorage.setItem("loggedInUser", "admin");
              setuserlogged("admin");
              navigate('/admindashboardhome')
            } else if (userData.role === 'user') {
              // localStorage.setItem("loggedInUser", "coach");
              // setuserlogged("coach");
              // navigate('/coachdashboardhome')
              navigate('/')
            } else if (userData.role === 'advisor') {
              // localStorage.setItem("loggedInUser", "advisor");
              // setuserlogged("advisor");
              // navigate('/coachdashboardhome')
              navigate('/')

            }
        
      };
  
      
    };
  return (
    <>
    <div className='container'>
    <div class=" d-flex justify-content-center mt-4 mb-5">
    <img src="./img/user/login/amz_bestsell-2-mo 1.png" alt="coachmasterlogo" className='img-fluid'/>
</div>
<div class=" d-flex justify-content-center mt-5">
    <img src="./img/user/login/Group 1686553581.png" alt="coachmasterlogo" className='img-fluid mt-5'/>
</div>
{/* <h2 className="  my-4 text-center font-500" style={{ letterSpacing: "1px",fontSize:'36px' }}>{selectedSport[0].toUpperCase() + selectedSport.slice(1)}</h2> */}

{/* <h3 className=" mb-1 mt-4 pb-3 text-center text-default font-600" style={{ letterSpacing: "1px" }}>Sign Up Successful!</h3> */}
<div className="d-flex justify-content-center align-items-center">
    <div className="col-md-3">
    <h3 className=" mb-3 mt-4 pb-3 text-center text-default font-600" style={{ letterSpacing: "1px" }}>Sign Up {signUpDone}!</h3>
        <p className="mb-2 pb-3 text-center">Congrats, your account was created successfully! Now you can design your {selectedField} field in the {selectedSport} club.</p>
        <div className="mb-4 mt-4  mx-auto">
                            <button onClick={handleContinue} className="btn btn-success rounded-pill px-5 py-2 shadow w-100">Continue</button>
                          </div>
    </div>
</div>

    </div>
    <ToastContainer/>
    </>
    
  )
}

export default SignUpSuccessfull