import React, { useEffect, useState } from 'react'
import AdminSidebar from '../AdminSidebar'
import AdminHeader from '../AdminHeader'
import { callApi } from '../../../Api/ApiCaller';
import routes from '../../../Api/routes';

const AdminDashboardSettingsTerms = () => {
  const [terms, setTerms] = useState([]);

  const [isloading, setIsLoading] = useState(false);
  const getTerms = () => {
    
    let getRes = (res) => {
      
      setTerms(res.doc[0]);
      // console.log(res);
    };

    callApi("GET", routes.terms, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };

  useEffect(() => {
    getTerms();
  }, []);
  return (
    <>
        <div className="overflow-hidden">
        <div className="row">
         
          <AdminSidebar/>
      
          <div className="col-md-10">
    
          <AdminHeader/>
            <div className='container my-4'>
            <h2 className=''>Terms Of Use</h2>
             <p className='text-secondary mt-3' style={{fontSize:'21px'}}>{terms?.Term}</p>
             {/* <p className='text-secondary' style={{fontSize:'21px'}}>Vitae odio orci amet convallis viverra morbi. Eu habitant eget platea nisl imperdiet massa. Id viverra donec rhoncus risus luctus pellentesque et faucibus proin. Tristique aliquam arcu tortor in diam risus nunc. In erat imperdiet malesuada feugiat. Eget arcu tortor ornare lacus est turpis in. Est tincidunt ridiculus elit nibh volutpat. Nibh neque in amet elit lacus mi pretium. Fusce quam ultrices faucibus sagittis in pellentesque cum est eget. Risus donec commodo odio lectus auctor pellentesque scelerisque interdum. Odio in sit duis tristique viverra non ut aliquam.</p>
             <p className='text-secondary' style={{fontSize:'21px'}}>Ipsum gravida egestas diam quis dignissim arcu quam. Netus tellus sit dui nisl sem in sed adipiscing. Volutpat id eget amet feugiat urna. Magna tellus mus hac vitae aliquam. Auctor lacus dui pretium tempus fames ac risus augue pharetra. Sociis varius eros sagittis senectus. Vel morbi enim eu ultrices lobortis purus. Ultrices habitasse massa metus sollicitudin eget pellentesque amet vel sed. Sed sit mattis ullamcorper scelerisque enim. Arcu ac risus at tortor condimentum convallis suscipit. Integer est turpis mattis eget duis a.
             Enim nullam auctor nunc consectetur fusce consectetur quis. Aliquam egestas tellus varius eget eget praesent. Bibendum magna interdum quam tellus id sit justo pellentesque ut. Egestas sed urna eleifend varius scelerisque enim dapibus. Placerat et dolor consectetur consequat. Proin mauris volutpat diam cursus consectetur non. Bibendum velit diam sed cursus semper mollis fermentum. Cursus massa nulla neque vitae. Enim gravida maecenas lobortis sed et ac egestas aliquet. Turpis viverra id pretium semper ullamcorper.
             </p> */}
            </div>
           
          </div>
          
        </div>
      </div>
        
        </>
  )
}

export default AdminDashboardSettingsTerms