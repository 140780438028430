import React, { useState, useEffect } from 'react';
import AdminSidebar from './AdminSidebar';
import AdminHeader from './AdminHeader';
import './admindashboard.css';
import { Link, useLocation } from 'react-router-dom';
import { callApi } from '../../Api/ApiCaller';
import routes from '../../Api/routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const notesData = [
  { id: 1, title: 'Note 1', content: 'Content for Note 1' },
  { id: 2, title: 'Note 2', content: 'Content for Note 2' },
  { id: 3, title: 'Note 3', content: 'Content for Note 3' },
  { id: 3, title: 'Note 4', content: 'Content for Note 4' },
  { id: 3, title: 'Note 5', content: 'Content for Note 5' },
  { id: 3, title: 'Note 6', content: 'Content for Note 6' },
  { id: 3, title: 'Note 7', content: 'Content for Note 7' },
  { id: 3, title: 'Note 8', content: 'Content for Note 8' },
  { id: 3, title: 'Note 9', content: 'Content for Note 9' },
  { id: 3, title: 'Note 10', content: 'Content for Note 10' },
  // Add more notes here
];

const AdminDashboardNotesView = () => {
  const [selectedNote, setSelectedNote] = useState(notesData[0]);
  const [notes, setnotes] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const noteId = queryParams.get("noteId");

  const getNotes = () => {
    setIsLoading(true);
    let getRes = (res) => {
      setIsLoading(false);
      setnotes(res.notes);
      console.log(res.notes);
    };

    callApi("GET", routes.notes, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };

  useEffect(() => {
    getNotes();
  }, []);

  const handleNoteClick = (note) => {
    setSelectedNote(note);
  };

  useEffect(() => {
    let getRes = (res) => {
      setSelectedNote(res.note);
    };

    callApi(
      "GET",
      routes.noteDetail + "/" + noteId,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  }, [noteId]);
const deleteNote = (note) => {
    let getRes = (res) => {
      if (res.status === 201 ) {
        getNotes();
        setSelectedNote('');
        toast.success(res.message, {
          autoClose: 1500
          })
            
      } else {
        toast.error(res.message, {
              autoClose: 1500
              })
      }
    };

    callApi("DELETE", routes.deleteNote+'/'+note, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };
  return (
    <>
      <div className="overflow-hidden">
        <div className="row">
          <AdminSidebar />
          <div className="col-md-10">
            <AdminHeader />
            <div className="container my-4">
              <main className="content">
                <div className="p-0">
                  <div className="card rounded-0" style={{ maxHeight: "664px" }}>
                    <div className="row g-0">
                      <div className="col-12 col-md-6 p-2">
                        <div className="left-chats">
                          {notes &&
                            notes.map((note, index) => (
                              <div
                                key={index}
                                className={`left-notes mx-3 my-2 rounded-0 py-3 ${
                                  selectedNote?._id === note._id ? "selected-note" : ""
                                }`} // Conditionally apply the 'selected-note' class
                                onClick={() => handleNoteClick(note)}
                                style={{ cursor: "pointer" }}
                              >
                                <div className="d-flex justify-content-between px-2">
                                  <div className="d-flex justify-content-center">
                                    <img
                                      className="mt-2 mx-2 note-icon"
                                      src="./img/Admin/Notes/notes.png"
                                      alt=""
                                      style={{ height: "40px", width: "40px" }}
                                    />
                                    <img
                                      className="mt-2 mx-2 note-icon-hover"
                                      src="./img/Admin/Notes/whitenotes.png"
                                      alt=""
                                      style={{ height: "40px", width: "40px" }}
                                    />
                                    <h5 className="my-3 mx-0">Note {index + 1}</h5>
                                  </div>
                                  <Link to="/admindashboardnotesview">
                                    <img
                                      className="p-3 img-fluid note-icon"
                                      src="./img/Admin/Notes/Group 1686553907Arrow - Down 2.png"
                                      alt=""
                                    />
                                    <img
                                      className="p-3 img-fluid note-icon-hover"
                                      src="./img/Admin/Notes/whitearrow .png"
                                      alt=""
                                    />
                                  </Link>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="position-relative">
                          <div className="notes-view p-4">
                            {selectedNote && (
                              <>
                                <p>{selectedNote?.note}</p>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
              <div className="mt-4 d-flex justify-content-end">
                <div className="row w-auto">
                  <div className="col-md-6">
                    <button
                      type="button"
                      className="btn btn-outline-success rounded-pill px-3 mx-3 py-2 w-100 shadow"
                      onClick={() => deleteNote(selectedNote?._id)}
                    >
                      Delete Note
                    </button>
                  </div>
                  <div className="col-md-6">
                    <Link 
                    to={{
                      pathname: "/admindashboardnotesedit",
                      search: `?noteId=${selectedNote?._id}`, 
                    }}
                      type="button"
                      className="btn btn-success rounded-pill px-5 mx-3 py-2 shadow w-100"
                    >
                      Edit
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer/>
            {isloading && (
        <div className="loader">
          <div></div>
        </div>
      )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboardNotesView;
