import React, { useEffect, useState } from 'react'
import AdminSidebar from './AdminSidebar'
import AdminHeader from './AdminHeader'
import data from '@emoji-mart/data'
import './Admindashboardmessages.css';
import Picker from '@emoji-mart/react'
import { useSocket } from '../Context/SocketContext';
import { callApi } from '../../Api/ApiCaller';
import routes from '../../Api/routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const AdminDashboardMessages = () => {
	const socket = useSocket();
	const storedUserData = localStorage.getItem('userData');
    const [user, setUser] = useState(storedUserData ? JSON.parse(storedUserData) : null);
	const [chatHeads, setChatHeads] = useState([]);
  const [message, setMessage] = useState('');
    const [showPicker, setShowPicker] = useState(false);
	const [perLine, setPerLine] = useState(25);
	const [selectedFile, setSelectedFile] = useState(null);
    const [showSend, setShowSend] = useState(false);
    const [userEnterChat, setUserEnterChat] = useState(false);

	const [activeChat, setActiveChat] = useState(null);
    const [activeChatDetails, setActiveChatDetails] = useState(null);

    const [chatHistory, setChatHistory] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // State for the search term
  const [filteredChats, setFilteredChats] = useState(chatHeads);

	const formatTime = (timestamp) => {
		const date = new Date(timestamp);
		const options = { hour: 'numeric', minute: 'numeric', hour12: true };
		let formattedTime = new Intl.DateTimeFormat('en-US', options).format(date);
		formattedTime = formattedTime.replace('AM', 'am').replace('PM', 'pm');
		return formattedTime;
	};
	const getInvitedUsers = () => {
		setIsLoading(true);
		let getRes = (res) => {
		  setIsLoading(false);
		  setChatHeads(res.users);
		  setFilteredChats(res.users)
		//   console.log(res)
		};
	
		callApi("GET", routes.getInvitedUsers, null, setIsLoading, getRes, (error) => {
		  setIsLoading(false);
		  console.log("error", error);
		});
	  };
      const getColleagues = () => {
		setIsLoading(true);
		let getRes = (res) => {
		  setIsLoading(false);
		  setChatHeads(res.users);
		  setFilteredChats(res.users)
		//   console.log(res)
		};
	
		callApi("GET", routes.getColleagues, null, setIsLoading, getRes, (error) => {
		  setIsLoading(false);
		  console.log("error", error);
		});
	  };
	useEffect(() => {
        if (user?.role == "admin"){
            getInvitedUsers();
            
        }
		else{
            getColleagues();
        }
        if (user) {
			console.log(user);
            socket.emit('user-enter',  user );

            socket.emit('get-inboxes',  user );
            socket.on('get-inboxes', (data) => {
				console.log( data)
                // setChatHeads(data?.inboxes);
            });

            // return () => {
            //     socket.off('inboxes');
            //     if (activeChat) {
            //         socket.emit('leave-room',  user, activeChat );
            //     }
            // };
        }
    }, [user]);
    useEffect(() => {
      setFilteredChats(
        chatHeads.filter(chat => 
          chat.email.toLowerCase().includes(searchTerm.toLowerCase())||
          chat.role.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }, [searchTerm, chatHeads]);
    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value); // Update search term as the user types
    };
	const enterChat = (chat) => {
		const endUser = chat?._id; 
		console.log(endUser);
		// console.log(chat);
        setActiveChatDetails(chat);
        setActiveChat(endUser);
        socket.emit('join-chat',  user, endUser );
        socket.on('messages', (data) => {
			setUserEnterChat(true);
            setChatHistory(data?.messages);
            console.log(data);
        });
    };
    const handleInputChange = (e) => {
        setMessage(e.target.value);
		setShowSend(e.target.value.trim() !== '');
    };

	const handleEmojiClick = (emoji) => {
		setMessage(message + emoji.native);
	};

    const togglePicker = () => {
        setShowPicker(!showPicker);
    };
	const handlepicker = () => {
        setShowPicker(false);
    };
    const handleSend = () => {
        if (message.trim() !== '' && activeChat) {
            // const newMessage = {
            //     user,
            //     to: activeChat,
            //     message,
            //     messageType: 'text',
            // };
            socket.emit('send-message', user, activeChat,message);
            
            setMessage('');
        }
    };
	const truncateText = (text, maxLength) => {
		if (text.length > maxLength) {
		  return text.substring(0, maxLength) + '....';
		}
		return text;
	  };

	useEffect(() => {
		// Function to scroll to the bottom of the chat container
		const scrollToBottom = () => {
		  const chatContainer = document.querySelector('.chat-messages');
		  if (chatContainer) {
			chatContainer.scrollTop = chatContainer.scrollHeight;
		  }
		};
	
		// Call the scrollToBottom function when the component mounts
		scrollToBottom();
		const calculatePerLine = () => {
            const emojiPickerWrapper = document.querySelector('.emoji-picker-wrapper');
            if (emojiPickerWrapper) {
                const wrapperWidth = emojiPickerWrapper.clientWidth;
                const emojisPerLine = Math.floor(wrapperWidth / 36); // Assuming each emoji button is 36px wide
                setPerLine(emojisPerLine);
            }
        };

        // Call the calculatePerLine function when the component mounts
        calculatePerLine();

        // Recalculate the number of emojis per line when the window is resized
        window.addEventListener('resize', calculatePerLine);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', calculatePerLine);
        };
	  }, [chatHistory]);
  return (
    <>
    <div className="overflow-hidden">
        <div className="row">
          {/* Sidebar */}
          <AdminSidebar/>
          {/* Main Content */}
          <div className="col-md-10">
          {/* Header */}
          <AdminHeader/>
          <div className='container '>
            <h3 className='fw-bold'>Messages</h3>
            <div className=" my-4">
            <main className="content">
    <div className=" p-0">

	

		<div className="card rounded-0">
			<div className="row g-0">
				<div className="col-12 col-lg-5 col-xl-3 p-2">

				<div className='left-chats'>
				<div className="px-2 ">
                  <div className="d-flex align-items-center " >
                    <div className="flex-grow-1 position-relative " >
                      <input type="text" className="form-control my-1 border-0 py-2" placeholder="Search User" style={{backgroundColor:'#FAFAFA'}} value={searchTerm}
              onChange={handleSearchChange}/>
                      <div className="position-absolute top-50 end-0 translate-middle-y">
                        <i className="fas fa-search text-muted mx-3 "></i> 
                      </div>
                    </div>
                  </div>
                </div>

{filteredChats.map((chat) => (
                                        <button className="list-group-item list-group-item-action border-0 p-2" onClick={() => enterChat(chat)}>
                                            <div className="d-flex align-items-start border-bottom pb-2">
                                                <img src={chat?.avatar} className="rounded-circle mr-1" alt="Chris Wood" width="44" height="44" />
                                                <div className='mx-2 w-100'>
                                                    <div className="d-flex justify-content-between " style={{ fontSize: '14px' }}>
                                                        <div className=" ml-3">
                                                            <span><b>{chat?.role}</b></span>
                                                        </div>
                                                        {/* <div><span className='text-muted'>{formatTime(chat?.updatedAt)}</span></div> */}
                                                    </div>
                                                    {/* <div style={{ fontSize: '14px' }} className='text-muted'><span>{truncateText(chat?.lastMessage, 31)}</span></div> */}
                                                    <div style={{ fontSize: '14px' }} className='text-muted'><span>{truncateText(chat?.email, 31)}</span></div>

                                                </div>
                                            </div>
                                        </button>
                                    ))}
				</div>
					
					{/* <a href="#" className="list-group-item list-group-item-action border-0">
						<div className="badge bg-success float-right">2</div>
						<div className="d-flex align-items-start">
							<img src="https://bootdey.com/img/Content/avatar/avatar2.png" className="rounded-circle mr-1" alt="William Harris" width="40" height="40"/>
							<div className="flex-grow-1 ml-3">
								William Harris
								<div className="small"><span className="fas fa-circle chat-online"></span> Online</div>
							</div>
						</div>
					</a> */}
					
				</div>
				<div className="col-12 col-lg-7 col-xl-9 p-2">
					<div>
					<div className="py-2 px-2 border-bottom">
						<div className="d-flex align-items-center py-1">
							{/* <div className="position-relative">
								<img src="./img/inbox/Ellipse 2371.png" className="rounded-circle mr-1" alt="Sharon Lessman" width="44" height="44"/>
							</div> */}
							{userEnterChat ? <div className="flex-grow-1 pl-3 mx-3">
								<strong>{activeChatDetails?.email}</strong>
								{activeChatDetails?.isActive ? <div className="text-muted small">Active Now <span className="fas fa-circle chat-online fa-xs mx-2"></span></div>: 
                                                                    <div className="text-muted small">Offline <span className="fas fa-circle chat-offline fa-xs mx-2"></span></div>}
							</div> : 
							<div>
								<span>Please Select the chat</span></div>
							}
							{/* <div>
                            <i className="fa-solid fa-lg fa-ellipsis-vertical"></i>
							</div> */}
						</div>
					</div>

					<div className="position-relative">
						<div className="chat-messages p-4">
						{userEnterChat ? <div>
						{chatHistory && chatHistory.slice().reverse().map((msg, index) => (
							<div key={index} className={`chat-message-${msg.sender._id === user?._id ? 'right' : 'left'} pb-4`}>
							<div>
								<img src={msg.sender.avatar} className="rounded-circle mr-1" alt="User" width="48" height="48" />
							</div>
							<div>
								
									<div className={`flex-shrink-1 py-4 px-3 mx-3 ${msg.sender._id === user?._id ? 'bg-success' : 'bg-light'}`} style={{ borderRadius: msg.sender._id === user?._id ? '16px 4px 16px 16px' : '4px 16px 16px 16px', color: msg.sender._id === user?._id ? 'white' : 'black' }}>
										{msg.message}
									</div>
								
								<div className={`text-muted ${msg?.sender?._id === user?._id ? 'text-end' : ''} small text-nowrap mt-1 mx-3`}>
									{formatTime(msg.createdAt)} {msg?.isSeen ? <img src="./img/Admin/Messages/Frame (8).png" alt="" /> : null}
								</div>
							</div>
						</div>						
))}
						</div> : 
						<div>
							<div  className=" d-flex justify-content-center mt-3">
            <img src="./img/Admin/7182231_3582363 1.png" alt="" className='img-fluid' style={{height:'155px', width:'155px'}}/>
            </div>
            <h5 className='text-center text-secondary'>No Chat Selected Yet</h5>
						</div>
						}
						</div>
						
					</div>

					<div className="flex-grow-0 py-3 px-4 border-top" >
    <div className="d-flex justify-content-center">
	<div>
            <img src="./img/Admin/Messages/Group 1686553947.png" onClick={togglePicker} alt="" />
        </div>
        <div className="input-group">
            <input
                type="text"
                className="form-control border-0"
                placeholder="Type your message"
                value={message}
                onChange={handleInputChange}
                onClick={handlepicker}
            />
            {/* <button className="btn btn-primary" onClick={handleSend}>Send</button> */}
			
        </div>
        {/* <div>
            <input
                type="file"
                accept="/"** mid / 
                style={{ display: 'none' }}
                id="fileInput"
                onChange={handleFileChange}/>
            <label htmlFor="fileInput" className='mt-2'>
                <img src="./img/inbox/Frame (1).png" alt="Upload File" style={{ cursor: 'pointer' }} />
            </label>
           
        </div> */}
		{/* <div>
            <input
                type="file"
                accept="image/*,video/*" 
                style={{ display: 'none' }}
                id="imageInput"
                onChange={handleImageChange}
            />
            <label htmlFor="imageInput" className='mt-2 mx-2'>
                <img src="./img/inbox/Camera.png" alt="Upload Image" style={{ cursor: 'pointer' }} />
            </label>
           
        </div> */}
		 <div className='mt-2'>
            <img src="./img/Admin/Messages/Group 1686553948 (1).png" alt="" width='40px' height='40px' style={{cursor:'pointer'}} onClick={handleSend}/>
        </div>
    </div>
    <div className="">
	{showPicker && (
        <div className="emoji-picker-wrapper">
		<Picker
			onEmojiSelect={handleEmojiClick}
			autoFocus={true}
			emojiSize={24}
			emojiButtonSize={36}
			emojiButtonRadius={'100%'}
			emojiButtonColors={['#f00', 'pink', 'rgba(155,223,88,.7)']}
			emojiVersion={14}
			icons={'auto'}
			locale={'en'}
			maxFrequentRows={4}
			navPosition={'top'}
			perLine={perLine}
			previewEmoji={'point_up'}
			previewPosition={'none'}
			searchPosition={'sticky'}
			set={'native'}
			skin={1}
			theme={'auto'}
			
		/>
	</div>
    )}
	</div>
</div>


					</div>

				</div>
			</div>
			
		</div>
	</div>
</main>
        </div>
          </div>
          <ToastContainer/>
        </div>
        
   
           
          </div>
          
        </div>

    
    </>
  )
}

export default AdminDashboardMessages