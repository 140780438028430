// import { RedNotify } from "../helper/utility";
// import configureAppStore from "../redux/store";
// import { DeviceUUID } from "device-uuid";
// import { accessToken } from "../redux/userDataSlice";

// export const BASE_URL = "http://localhost:4500/api/v1";
export const BASE_URL =
  "https://api.coachmastersports.de/api/v1";

  export const Method = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    PATCH: "PATCH",
    DELETE: "DELETE",
  };
  
  export const Status = {
    SUCCESS: 200,
    ERROR: 400,
    AUTHENTICATION_FAIL: 401,
    NOT_FOUND: 400,
  };
  
  var defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  export const callApi = async (
    method,
    Url,
    bodyParams,
    setloading,
    onSuccess,
    onError,
    count = 0,
    multipart
  ) => {
  
    let token = localStorage.getItem("accessToken") ?? false;
    let refreshToken = localStorage.getItem("refreshToken") ?? false;
    // let UserData = localStorage.getItem("userData") ?? false;
    // const userDataString = localStorage.getItem("userData");
    // const refreshToken = userDataString ? JSON.parse(userDataString)?.refreshToken : false;
    console.log("token=>>", token);
    console.log("refreshToken=>>", refreshToken);
    console.log("Url ==>>", Url);

    if (multipart) {
      // console.log("multipart");
      defaultHeaders["Content-Type"] = "multipart/form-data";
    } else {
      // console.log(" no multipart");
      defaultHeaders["Content-Type"] = "application/json";
    }
    if (token) {
      defaultHeaders["Authorization"] = `Bearer ${token}`;
    }

    let fetchObject = {
      method: method,
      headers: defaultHeaders,
      body:
        method == "GET"
          ? null
          : method == "DELETE"
          ? null
          : multipart
          ? bodyParams
          : JSON.stringify(bodyParams),
    };

    if (bodyParams == null) {
      delete fetchObject.body;
    }
    try {
      setloading(true);
      let response = await fetch(Url, fetchObject);
      // console.log('Response  ==>>   ', response);
      let responseJson = await response.json();
      console.log("Fetch Response ==>>   ", responseJson);
      if (responseJson?.message == "jwt expired" && count < 2 && refreshToken) {
        let fetchObject = {
          method: "POST",
          headers: defaultHeaders,
          
        };
  
        await fetch(`${BASE_URL}/refresh-token/${refreshToken}`, fetchObject)
          .then(async (res) => {
            // console.log("jiiiiiiiii")
            let resJson = await res.json();
            console.log("REFRESH refreshResponse ==>  ", resJson);
            localStorage.setItem("accessToken", resJson.token);
            localStorage.setItem("refreshToken", resJson.refreshToken);
            console.log(localStorage.getItem("accessToken"));
            console.log(localStorage.getItem("refreshToken"));

            // configureAppStore.dispatch(accessToken(resJson.data.accessToken));
            callApi(
              method,
              Url,
              bodyParams,
              setloading,
              onSuccess,
              onError,
              count + 1
            );
          })
          .catch((err) => {
            console.log("error refresh token=> ", err);
            setloading(false);
          });
      } else if (responseJson?.status < 600) {
        onSuccess(responseJson);
        //   if (responseJson?.errorType) {
        //     GreenSnackbar(responseJson?.errorType);
        //   } else if (responseJson?.message) GreenSnackbar(responseJson?.message);
        setloading(false);
      } else {
        onError(responseJson);
        //   if (responseJson?.errorType) {
        //     GreenSnackbar(responseJson?.errorType);
        //   } else if (responseJson?.message) GreenSnackbar(responseJson?.errorType);
        setloading(false);
      }
    } catch (error) {
      //console.log("eror==================", error);
      // RedNotify("Network request failed");
      setloading(false);
      //console.log('Api call try catch error:', error.message);
    }

    
};

