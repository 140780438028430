export const BASE_URL =
  "https://api.coachmastersports.de/api/v1";

export default {
  // -----AUTH------//
  authenticateAppImage: BASE_URL + "/app-image",
  signUp: BASE_URL + "/auth/sign-up",
  signIn: BASE_URL + "/auth/log-in",
  signInAdmin: BASE_URL + "/user/admin/login",
  sendOTP: BASE_URL + "/user/sendOTP",
  verifyOTP: BASE_URL + "/auth/otp-verification",
  forgotPassword: BASE_URL + "/auth/forgot-password",
  verifyForgotPasswordOtp: BASE_URL + "/auth/forgot-verification",
  resetPassword: BASE_URL + "/auth/reset-password",
  resendOtp: BASE_URL + "/auth/resend-otp",
  changePassword: BASE_URL + "/settings/change-password",
  verifyOTPresetPassword: BASE_URL + "/user/verifyOTPResetPassword",
  logOut: BASE_URL + "/settings/log-out",
  editUser: BASE_URL + "/users/edit-profile",
  completeProfile: BASE_URL + "/user/build-profile",
  otpForDeletedMe: BASE_URL + "/users/send-otp-for-deleting",
  deleteMe: BASE_URL + "/users/delete-me",
  profile:BASE_URL + "/users/me",
  editProfile: BASE_URL + "/settings/profile-updation",
  // -----Stats-------//
  getStats: BASE_URL + "/user/stats",

  /// User
  user: BASE_URL + "/user",
/// About Us
aboutUs: BASE_URL + "/about/read-about",
helpCenter: BASE_URL + "/help-center/ask-for-help", 
socialMediaLinks: BASE_URL + "/social-media-links",
terms: BASE_URL + "/terms/read-term",
policy: BASE_URL + "/policy/read-privacy",

// Reviews

reviews: BASE_URL + "/reviews",
  // Notes
 notes: BASE_URL + "/notes/read-all-notes",
 noteDetail: BASE_URL + "/notes/read-one-note",
 createNote: BASE_URL + "/notes/create-note",
 editNote: BASE_URL + "/notes/update-note",
 deleteNote: BASE_URL + "/notes/delete-one-note",

   // Squad
   squad: BASE_URL + "/squad/get-squad",
   playerDetail: BASE_URL + "/squad/get-squad-player",
   createPlayer: BASE_URL + "/squad/create-squad",
   deletePlayer: BASE_URL + "/squad/delete-squad-player",
   editPlayer: BASE_URL + "/squad/update-squad-player",

   // Dashboard
 dashboardImages: BASE_URL + "/board/get-pic-and-logo",
 uploadDashboardImages: BASE_URL + "/board/upload-picture",
 addBankDetails: BASE_URL + "/users/stripe/add-bank",
 checkBankInfo: BASE_URL + "/users/check-bank-info",
 getInvitedUsers: BASE_URL + "/admin/invited-users",
 getColleagues: BASE_URL + "/admin/colleagues",
 getInvitedLink: BASE_URL + "/admin/share-link",
 updateUserRole: BASE_URL + "/admin/change-role",
//  coursedetails: BASE_URL + "/courses/get-one-course-web",

// Year Planner
yearPlanner: BASE_URL + "/yearPlanner/get-yearPlanner",
getOneYearPlanner: BASE_URL + "/yearPlanner/get-one-plan",
updateOneYearPlanner: BASE_URL + "/yearPlanner/update-year-plan",
createYearPlanner: BASE_URL + "/yearPlanner/create-year-planner",
createYearPlannerMatchDay: BASE_URL + "/yearPlanner/create-team-planner",
getYearPlannerMatchDay: BASE_URL + "/yearPlanner/get-team",
createField: BASE_URL + "/fields/create-field",
getOneField: BASE_URL + "/fields/get-field-one-planner",
getAllFieldsOnePlanner: BASE_URL + "/fields/all-fields-one-planner",
uploadCourseAssignment: BASE_URL + "/progress/upload-course-assignment",
markQuizComplete: BASE_URL + "/progress/mark-quiz-complete",
getOneOngoingWorkout: BASE_URL + "/progress/get-one-ongoing-workout",
markCourseComplete: BASE_URL + "/progress/mark-course-complete",
markWorkoutComplete: BASE_URL + "/progress/mark-workout-complete",
getallprogress: BASE_URL + "/progress/progress-tracking-for-all-courses/?year = 2024",
// Interests

interests: BASE_URL + "/interests",

// Goals

goals: BASE_URL + "/goals",

// Goals

expertise: BASE_URL + "/expertise",

// Contact Us
contactUs: BASE_URL + "/contact/contact-us",

// Course Instructor
myCourses: BASE_URL + "/courses/get-my-courses",
createCourse: BASE_URL + "/courses/create-course",
editCourse: BASE_URL + "/courses/update-course",
deleteCourse: BASE_URL + "/courses/delete-course",
// Workout Trainer
myWorkouts: BASE_URL + "/workouts/get-my-workouts",
createWorkout: BASE_URL + "/workouts/create-workout",
};
