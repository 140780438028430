import React, { useEffect, useState } from 'react'
import AdminSidebar from '../AdminSidebar'
import AdminHeader from '../AdminHeader'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import routes from '../../../Api/routes';
import { callApi } from '../../../Api/ApiCaller';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const AdminDashboardYearPlannerMatchDay = () => {
  const [yearPlanner, setYearPlanner] = useState([])
  const navigate = useNavigate();
  const location = useLocation();
  const { yearPlannerId } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);
  const [userlogged, setuserlogged] = useState(localStorage.getItem('loggedInUser'));
  let userData = JSON.parse(localStorage.getItem('userData'));
  const getOneYearPlannerDetail = () => {
      
    let getRes = (res) => {
      setYearPlanner(res.plan)
      
      console.log(res.plan)
    };

    callApi("GET", routes.getOneYearPlanner+'/'+yearPlannerId, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };
  const getYearPlannerDetail = () => {
    setIsLoading(true);
    let getRes = (res) => {
      setIsLoading(false);
      //  localStorage.setItem("userData", res?.data?.user);
      setPlayers(res.team.team);
      setBenchInputFields(res.team.benchPlayers);
      setInputFields(res.team.opponentTeam);
      // setYearPlanner(res.team.yearPlanner)
      // setproducts(res.data.data.products);
      console.log(res.team)
    };

    callApi("GET", routes.getYearPlannerMatchDay+'/'+yearPlannerId, null, setIsLoading, getRes, (error) => {
      setIsLoading(false);
      console.log("error", error);
    });
  };
  useEffect(() => {
    getOneYearPlannerDetail();
    getYearPlannerDetail();
    
  }, []);
  const handleYearPlanner = (e) => {
    console.log(players);
      console.log(benchInputFields);
      console.log(inputFields);
      e.preventDefault();
      let getRes = (res) => {
        if (res.status == 200) {
  
          toast.success(res.message, {
                  autoClose: 1500,
                  onClose: () => {
            navigate(`/admindashboardyearplanner`)
            }
                });
        } else {
          toast.error(res.message, {
                autoClose: 1500
                })
        }
      }; 
      let body = {
        team: players,
        benchPlayers: benchInputFields,
        opponentTeam: inputFields,
      
      };
      callApi("POST", routes.createYearPlannerMatchDay+'/'+yearPlannerId, body, setIsLoading, getRes, (error) => {
        console.log("error", error);
      });
  };
  const getRedirectPath = () => {
    if (userlogged === 'admin') {
      

      return '/yearplannereditor';
    } else if (userlogged === 'coach') {
      return '/yearplannereditor';
    } else {
      return '/advisoryearplannerdetailsfield';
    }
  };
  const [players, setPlayers] = useState([{ playerInfo: '' }]);

  const addPlayer = () => {
    // console.log(yearPlannerId);
    // Prevent adding empty player info
    if (players.length < 15 && players[players.length - 1].playerInfo.trim() !== '') {
      setPlayers([...players, { playerInfo: '' }]);
    } else {
      toast.error("Please fill in the current player field before adding a new one");
    }
  };

const handleChange = (index, value) => {
  const updatedPlayers = players.map((player, i) => {
    if (i === index) {
      return { ...player, playerInfo: value };
    }
    return player;
  });
  setPlayers(updatedPlayers);
};

  // const inputFields = Array.from({ length: 10 });
  // const benchInputFields = Array.from({ length: 15 });
  const [benchInputFields, setBenchInputFields] = useState([{ playerName: '' }]);

  const addBenchPlayer = () => {
    // Prevent adding empty bench player info
    if (benchInputFields.length < 15 && benchInputFields[benchInputFields.length - 1].playerName.trim() !== '') {
      setBenchInputFields([...benchInputFields, { playerName: '' }]);
    } else {
      toast.error("Please fill in the current bench player field before adding a new one");
    }
  };

  const handleBenchPlayerChange = (index, value) => {
    const updatedFields = benchInputFields.map((field, i) => {
      if (i === index) {
        return { ...field, playerName: value };
      }
      return field;
    });
    setBenchInputFields(updatedFields);
  };
  const [inputFields, setInputFields] = useState([{ opponentPlayer: '' }]);

  // Function to add a new opponent player input
  const addOpponentPlayer = () => {
    // Prevent adding empty opponent player info
    if (inputFields.length < 15 && inputFields[inputFields.length - 1].opponentPlayer.trim() !== '') {
      setInputFields([...inputFields, { opponentPlayer: '' }]);
    } else {
      toast.error("Please fill in the current opponent player field before adding a new one");
    }
  };

  // Handle input changes
  const handleInputChange = (index, value) => {
    const updatedFields = inputFields.map((fieldData, i) => {
      if (i === index) {
        return { ...fieldData, opponentPlayer: value };
      }
      return fieldData;
    });
    setInputFields(updatedFields);
  };
  return (
    <>
    <div className="overflow-hidden">
        <div className="row ">
          {/* Sidebar */}
          <AdminSidebar/>
          {/* Main Content */}
          <div className="col-md-10 ">
          {/* Header */}
          <AdminHeader/>
            <div className='container-fluid my-2 mx-md-2'>
              {/* <h3 className='fw-bold'>My Messages</h3> */}
              <div className='row '>
              
              <div className="col-md-3 col-lg-2 my-2">
<div className='shadow px-2 py-2 matchdayyearplannercardcolor rounded-0 '>
  <h4 className='py-1'>Planner Type</h4>
  <h5 className='py-3'>{yearPlanner?.plannerType}</h5>
</div>
              </div>
              <div className="col-md-3 col-lg-2 my-2">
<div className='shadow px-2 py-2 matchdayyearplannercardcolor rounded-0 '>
  <h4 className='py-1'>Date</h4>
  <h5 className='py-3'>{yearPlanner?.day}-{yearPlanner?.month}-{yearPlanner?.year}</h5>
</div>
              </div>
              <div className="col-md-3 col-lg-2 my-2">
<div className='shadow px-2 py-2 matchdayyearplannercardcolor rounded-0 '>
  <h4 className='py-1'>From</h4>
  <h5 className='py-3'>{yearPlanner?.from}</h5>
</div>
              </div>
              <div className="col-md-3 col-lg-2 my-2">
<div className='shadow px-2 py-2 matchdayyearplannercardcolor rounded-0 '>
  <h4 className='py-1'>To</h4>
  <h5 className='py-3'>{yearPlanner?.to}</h5>
</div>
              </div>
              <div className="col-md-3 col-lg-2 my-2">
<div className='shadow px-2 py-2 matchdayyearplannercardcolor rounded-0 '>
  <h4 className='py-1'>Match Venue</h4>
  <h5 className='py-3'>{yearPlanner?.matchVenue}</h5>
</div>
              </div>
              
              </div>
              <div className="row">
                <div className="col-md-6">
                <h2 className='py-1 mt-3 mt-md-0'>Line up</h2>
                <div className="row">
                  <div className="col-6 col-md-6">
                  <Link
  to={{
    pathname: getRedirectPath(),
    search: `?yearPlannerId=${yearPlanner?._id}&fieldType=lineUp`,
  }}
>
  <img className="img-fluid" src={userData?.sport === 'soccer' ? `./img/Admin/YearPlanner/MatchDay/Group 175.png` : `./img/Admin/YearPlanner/MatchDay/HandBallField.png`} alt="" />
</Link>
                  </div>
                  <div className="col-6 col-md-5">
{/* {inputFields.map((_, index) => (<div key={index} data-mdb-input-init class="form-outline mb-2">
    <label class="form-label font-600 mb-0" for="form2Example18">Player Name& Jersey NO</label>
    <input type="text" id="form2Example18" class="form-control form-control-sm border-0 border-bottom" style={{border: "2px solid #C1C1C1", borderRadius: "0"}} />
</div>))} */}
                  {/* Here we can add playes according to our marzi */}
                  <div>
  {players.map((player, index) => (
    <div key={index} data-mdb-input-init className="form-outline mb-4">
      <label className="form-label font-600" htmlFor={`player${index}`}>
        Player Name & Jersey NO
      </label>
      <input
        type="text"
        id={`player${index}`}
        className="form-control form-control-sm border-0 border-bottom"
        style={{ border: '2px solid #C1C1C1', borderRadius: '0' }}
        value={player.playerInfo}
        onChange={(e) => handleChange(index, e.target.value)}
        placeholder="Player Name - Jersey NO"
      />
    </div>
  ))}
  <div className='d-flex justify-content-end'>
  {players.length < 15 && userData?.role === 'admin' && (
    <button type="button" className="btn btn-success rounded-pill px-5 py-2 shadow" onClick={addPlayer}>
      Add Player
    </button>
  )}
  </div>
</div>

                  </div>
                </div>
                <div>
      <div className="d-flex justify-content-between mt-3">
        <div className="d-flex justify-content-center">
          <img src="./img/Admin/YearPlanner/MatchDay/Group 1686554296mingcute_bank-line.png" className="mt-1" width="24" height="24" alt="" />
          <h3 className="mx-2" style={{ fontSize: '26px' }}>Bench</h3>
        </div>
        <div className="d-flex justify-content-center">
          <img src="./img/Admin/YearPlanner/MatchDay/mdi_3d-rotation.png" className="mt-1" width="24" height="24" alt="" />
          <h3 className="mx-2" style={{ fontSize: '26px' }}>Change</h3>
        </div>
      </div>

      <div className="row my-3">
        {benchInputFields.map((field, index) => (
          <div className="col-md-4 col-6" key={index}>
            <div data-mdb-input-init className="form-outline mb-2">
              <label className="form-label font-600 mb-0" htmlFor={`benchPlayer${index}`}>Player Name</label>
              <input
                type="text"
                id={`benchPlayer${index}`}
                className="form-control form-control-sm border-0 border-bottom"
                style={{ border: "2px solid #C1C1C1", borderRadius: "0" }}
                value={field.playerName}
                onChange={(e) => handleBenchPlayerChange(index, e.target.value)}
                placeholder="Player Name"
              />
            </div>
          </div>
        ))}
      </div>
      <div className='d-flex justify-content-end'>
      {benchInputFields.length < 15 && userData?.role === 'admin' &&(
        <button type="button" className="btn btn-success rounded-pill px-5 py-2 shadow" onClick={addBenchPlayer}>
          Add Bench Player
        </button>
      )}
    </div>
    </div>
                </div>
                <div className="col-md-6">
                <h2 className='py-1 mt-3 mt-md-0'>Standards</h2>
                <div className="row">
                  <div className="col-6 col-md-6">
                  <Link to={{
    pathname: getRedirectPath(),
    search: `?yearPlannerId=${yearPlanner?._id}&fieldType=standard1`,
  }}><img className='img-fluid' src={userData?.sport === 'soccer' ? `./img/Admin/YearPlanner/MatchDay/Frame 1686554899.png` : `./img/Admin/YearPlanner/MatchDay/Frame 1686554910.png`} alt="" /></Link>
                  </div>
                  <div className="col-6 col-md-6">
                  <Link to={{
    pathname: getRedirectPath(),
    search: `?yearPlannerId=${yearPlanner?._id}&fieldType=standard2`,
  }}><img className='img-fluid' src={userData?.sport === 'soccer' ? `./img/Admin/YearPlanner/MatchDay/Frame 1686554899.png` : `./img/Admin/YearPlanner/MatchDay/Frame 1686554910.png`} alt="" /></Link>
                  </div>
                  <div className="col-6 col-md-6">
                  <Link to={{
    pathname: getRedirectPath(),
    search: `?yearPlannerId=${yearPlanner?._id}&fieldType=standard3`,
  }}><img className='img-fluid' src={userData?.sport === 'soccer' ? `./img/Admin/YearPlanner/MatchDay/Frame 1686554899.png` : `./img/Admin/YearPlanner/MatchDay/Frame 1686554910.png`} alt="" /></Link>
                  </div>
                  <div className="col-6 col-md-6">
                  <Link to={{
    pathname: getRedirectPath(),
    search: `?yearPlannerId=${yearPlanner?._id}&fieldType=standard4`,
  }}><img className='img-fluid' src={userData?.sport === 'soccer' ? `./img/Admin/YearPlanner/MatchDay/Frame 1686554899.png` : `./img/Admin/YearPlanner/MatchDay/Frame 1686554910.png`} alt="" /></Link>
                  </div>
                  </div>
                  
                    
                    <h3 className='py-1 my-4'>Opponent Goals</h3>
                    <div className="row ">
                    <div className="col-5 col-md-3 ">
                    <><img className='img-fluid' src={userData?.sport === 'soccer' ? `./img/Admin/YearPlanner/MatchDay/Group 48095842.png` : `./img/Admin/YearPlanner/MatchDay/HandBall2.png`} alt="" /></>
                  </div>
                  <div className="col-7 col-md-8 ">
                  <div>
      <div className="row">
        {inputFields.map((_, index) => (
          <div className="col-md-6" key={index}>
            <div data-mdb-input-init className="form-outline mb-2">
              <label className="form-label font-600 mb-0" htmlFor={`opponentPlayer${index}`}>
                Player Name & Goals
              </label>
              <input
                type="text"
                id={`opponentPlayer${index}`}
                className="form-control form-control-sm border-0 border-bottom"
                style={{ border: "2px solid #C1C1C1", borderRadius: "0" }}
                value={inputFields[index].opponentPlayer}
                onChange={(e) => handleInputChange(index, e.target.value)}
                placeholder="Player Name - Goals"
              />
            </div>
          </div>
        ))}
      </div>

      {/* Button to add new opponent player */}
      {inputFields.length < 15 && userData?.role === 'admin' &&(
        <button type="button" className="btn btn-success rounded-pill px-5 py-2 shadow mt-3" onClick={addOpponentPlayer}>
          Add Opponent Player
        </button>
      )}
    </div>
                  <div className="container ">
<div className="mt-5 d-flex justify-content-end">
  <div className="row w-auto mt-2">
    
      {userData?.role === 'admin' && <button
        type="button"
        
        className="btn btn-success rounded-pill px-5 mx-3 py-2 shadow w-100"
        onClick={handleYearPlanner}
      >
        Done
      </button>}
    
  </div>
</div>
</div>
                  </div>
                  
                    </div>
                    
                </div>
              </div>
            </div>
            {isLoading && (
        <div className="loader">
          <div></div>
        </div>
      )}
            <ToastContainer/>
          </div>
          
        </div>
      </div>
    
    </>
  )
}

export default AdminDashboardYearPlannerMatchDay