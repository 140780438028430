import React, { useEffect, useState } from 'react'
import "./coachdashboard.css";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AdminSidebar from '../Admin/AdminSidebar';
import AdminHeader from '../Admin/AdminHeader';
import { callApi } from '../../Api/ApiCaller';
import routes from '../../Api/routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const CoachDashboardHome = () => {
    const location = useLocation();
    const [showModal, setShowModal] = useState(false);
    const [currentURL, setCurrentURL] = useState(location.pathname);
    const [userlogged, setuserlogged] = useState(localStorage.getItem('loggedInUser'))

let userData = JSON.parse(localStorage.getItem('userData'));
const [dashboardImages, setDashboardImages] = useState([]);
const [colleagues, setColleagues] = useState([]);
const [isloading, setIsLoading] = useState(false);
const getDashboardImages = () => {
  setIsLoading(true);
  let getRes = (res) => {
    setIsLoading(false);
    setDashboardImages(res.board[0]);
    console.log(userData);
  };

  callApi("GET", routes.dashboardImages+'/'+userData._id, null, setIsLoading, getRes, (error) => {
    setIsLoading(false);
    console.log("error", error);
  });
};
const getColleagues = () => {
  
  let getRes = (res) => {
    
    setColleagues(res.users);
    
  //   console.log(res)
  };

  callApi("GET", routes.getColleagues, null, setIsLoading, getRes, (error) => {
    
    console.log("error", error);
  });
  };
  
useEffect(() => {
  getDashboardImages();
  getColleagues();
}, []);
  const navigate = useNavigate();
    useEffect(() => {
      setCurrentURL(location.pathname);
    }, [location.pathname]);


      const handleLogout = () => {
        // Remove data of 'loggedInUser' from localStorage
        localStorage.removeItem('loggedInUser');
        // Update the userlogged state to false
        setuserlogged(false);
        navigate('/login');
    };
    const [filteredColleagues, setFilteredColleagues] = useState([]);

    useEffect(() => {
      setFilteredColleagues(colleagues);
    }, [colleagues]);
const handleSearch = (query) => {
if (query.trim() === '') {
// If search query is empty, show all users
setFilteredColleagues(colleagues);
} else {
// Otherwise, filter users based on the search query
const filtered = colleagues.filter(user => {
const emailMatch = user?.email?.toLowerCase().includes(query.toLowerCase());
const roleMatch = user?.role?.toLowerCase().includes(query.toLowerCase());
return emailMatch || roleMatch;
});
setFilteredColleagues(filtered);
}
};
  return (
    <>
      <div className="overflow-hidden">
        <div className="row">
          {/* Sidebar */}
          <AdminSidebar/>
          {/* Main Content */}
          <div className="col-md-10">
          {/* Header */}
          <AdminHeader handleSearch={handleSearch}/>
            <div className='container-fluid my-3 my-md-0'>
              {/* <h3 className='fw-bold'>My Courses</h3> */}
              <div className='row'>
              <div className="col-md-7 col-12 ">
           <div className="row  instructorcreatecoursemidborder p-2">
           <div>
                
                <div className="instructordropzoneuploadimg-container ">
    <div className="instructordropzoneuploadimg ">
         <img src={dashboardImages?.teamPicture ? dashboardImages?.teamPicture : `./img/Coach/Frame 1686554411 (2).png`}  alt="Uploaded" className="instructoruploaded-image" /> 
    </div>
</div>  

        </div>
    <div className='mt-5'>
    <div className="text-center d-flex flex-column justify-content-center mt-5">
    
        <div className="row ">
            <div className="col-md-6 ">
            <Link to='/coachdashboardyearplanner' className='text-dark' style={{textDecoration:'none'}}>
                <div className=" shadow mx-1 my-3 my-md-0 rounded-0 py-3">
                <img className='mt-2' src="./img/Admin/Calendar.png" alt="" />
                <h4 className='my-2'>Year Planner</h4>
                </div>
                </Link>
            </div>
            <div className="col-md-6 ">
            <Link to={userlogged === 'admin'? '/admindashboardsquad' : '/admindashboardsquadplayers'} className='text-dark' style={{textDecoration:'none'}}>
                <div className=" shadow mx-1 rounded-0 py-3">
                <img className='mt-2' src="./img/Admin/Frame (5).png" alt="" />
                <h4 className='my-2'>Squad</h4>
                </div>
                </Link>
            </div>
        </div>
        
    
</div>

                            
                            
    </div>




</div>

           </div>
           <div className="col-md-5 paddingtoadjustmiduploadlogo" >
           <div className="text-center mb-2 mt-md-3 position-relative">
            <label htmlFor="avatarInput">
                <img
                    src={dashboardImages?.clubLogo ? dashboardImages?.clubLogo : `./img/Admin/Frame 1686554412.png`} 
                    alt="avatar"
                    className="rounded-circle img-fluid"
                    style={{ width: '257px', height: '257px' }}
                />
            </label>
            
        </div>

        <div className=' mx-5 pb-4 mt-5' >
            <div className="shadow p-4 pt-5" style={{height:'100%'}}>
            <div className='position-relative'>
            <h4 className='fw-bold'>Coordinate with your </h4>
            <h4 className='fw-bold'>colleagues</h4>
            {/* <div  className=" d-flex justify-content-center mt-3">
            <img src="./img/Admin/7182231_3582363 1.png" alt="" className='img-fluid' style={{height:'155px', width:'155px'}}/>
            </div>
            <h5 className='text-center text-secondary'>No Colleague Yet</h5> */}

<div className="scroll-container-invite ">
    <table className="table">
    {filteredColleagues && filteredColleagues.map((user, index) => (
            <tr key={index}>
                <td width="60px">
                    <div className="imgBx">
                        <img src={user?.avatar} alt="" height='48' className='rounded-circle'/>
                    </div>
                </td>
                <td>
                    <p className='pt-2 fw-bold mb-0'>
                        {user?.email} <br />
                        <span className="d-inline-block fw-normal" style={{ fontSize: '14px' }}>{user?.role}</span>
                    </p>
                </td>
                
            </tr>
        ))}
    </table>
</div>
<div className="position-absolute" style={{ bottom: '50px', right: '10px', cursor:'pointer' }}>
        <Link to='/admindashboardmessages'><img src="./img/Admin/Caht icon.png" alt="Chat Icon" /></Link>
    </div>

            {/* <div className="mb-2 d-flex justify-content-center" style={{marginTop:'19%'}}>
                            <button type='button' className="btn btn-success rounded-pill mx-2 py-2 shadow " style={{height:'45px', width:'11rem'}}>Invite</button>
                          </div> */}
                          <div className="mt-2 d-flex justify-content-center">
                         <br />
                          </div>
            </div>
            </div>
        </div>
           </div>
              </div>
            </div>
            <ToastContainer/>
            {isloading && (
        <div className="loader">
          <div></div>
        </div>
      )}
          </div>
          
        </div>
      </div>
     
    </>
  )
}

export default CoachDashboardHome