import React, { useEffect, useState } from 'react'
import AdminSidebar from '../AdminSidebar'
import AdminHeader from '../AdminHeader'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { callApi } from '../../../Api/ApiCaller';
import routes from '../../../Api/routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const AdminDashboardYearPlannerTrainingSession = () => {
  
  const [yearPlanner, setYearPlanner] = useState([])
  const [yearPlannerFields, setYearPlannerFields] = useState([])

  const navigate = useNavigate();
  const location = useLocation();
  const { yearPlannerId } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);
  const [userlogged, setuserlogged] = useState(localStorage.getItem('loggedInUser'));
  let userData = JSON.parse(localStorage.getItem('userData'));
  const getOneYearPlannerDetail = () => {
    setIsLoading(true);
    let getRes = (res) => {
      setIsLoading(false);
      setYearPlanner(res.plan)
      
      console.log(res.plan)
    };

    callApi("GET", routes.getOneYearPlanner+'/'+yearPlannerId, null, setIsLoading, getRes, (error) => {
      setIsLoading(false);
      console.log("error", error);
    });
  };
  const getAllFieldsOneYearPlannerDetail = () => {
    
    let getRes = (res) => {
     
      setYearPlannerFields(res.field)
      
      console.log(res.field)
    };

    callApi("GET", routes.getAllFieldsOnePlanner+'/'+yearPlannerId, null, setIsLoading, getRes, (error) => {
     
      console.log("error", error);
    });
  };
  useEffect(() => {
    getOneYearPlannerDetail();
    getAllFieldsOneYearPlannerDetail();
  }, []);
  const getRedirectPath = () => {
    if (userlogged === 'admin') {
      return '/yearplannereditor';
    } else if (userlogged === 'coach') {
      return '/yearplannereditor';
    } else {
      return '/advisoryearplannerdetailsfield';
    }
  };
  const renderStars = (value) => {
    // console.log(value)
    const fullStars = Math.floor(value); 
    const remainingStars = 5 - fullStars; 
    const stars = [];
  
    // Add full stars
    for (let i = 0; i < fullStars; i++) {
      stars.push(<i key={i} class="fa-solid fa-star" style={{color: '#FFD43B'}}></i>);
    }
  
    // Add remaining stars
    for (let i = 0; i < remainingStars; i++) {
      stars.push(<i key={fullStars + i} class="fa-regular fa-star" style={{color: '#FFD43B'}}></i>);
    }
  
    return stars;
  };
  return (
    <>
    <div className="overflow-hidden">
        <div className="row">
          {/* Sidebar */}
          <AdminSidebar/>
          {/* Main Content */}
          <div className="col-md-10">
          {/* Header */}
          <AdminHeader/>
            <div className='container-fluid my-4'>
              {/* <h3 className='fw-bold'>My Training Session</h3> */}
              <div className='row'>
              <div className="col-md-5 mx-md-4">
<div className="row">
<div className="col-md-6">
<h2 className='py-1 mt-3 mt-md-0'>{yearPlanner?.day}-{yearPlanner?.month}-{yearPlanner?.year}</h2>
<h2 className='py-1 mt-3 mt-md-0'>{yearPlanner?.from} to {yearPlanner?.to}</h2>

                </div>
                <div className="col-md-6">
                <div className='rounded-0 p-3' style={{border:'2px solid black'}}>
                <h5 className='py-1 mt-3 mt-md-0'>Number: {yearPlanner?.numberOfPlayers}</h5>
                <h5 className='py-1 mt-3 mt-md-0'>Main emphasis: {yearPlanner?.mainEmphasis}</h5>
                </div>
     
              </div>
</div>
<div className='mt-3'>
{/* <div className='bg-light pt-2 pb-1 px-3  '>
<h4>Heading Here...</h4>
</div> */}
<div className='bg-light pt-2 pb-1 px-3  my-2'>
<h3 className='fw-bold'>Exercises</h3>

<div className='my-2'>
                            {yearPlanner.trainingSessions && yearPlanner.trainingSessions.map((hashtag, index) => (
                              <h5 key={index} className=""> {index+1}. {hashtag} </h5>
                            ))}
                          </div>
</div>
{/* <div className='bg-light pt-2 pb-1 px-3 rounded-4 my-2'>
<h5>Any Note...</h5>
</div>
<div className='bg-light pt-2 pb-1 px-3 rounded-4 my-2'>
<h5>Any Note...</h5>
</div> */}
</div>
<div className="row">
<div className="col-6 col-md-4">
<Link to={{
    pathname: getRedirectPath(),
    search: `?yearPlannerId=${yearPlanner?._id}&fieldType=trainingSession1`,
  }}><img className=' mt-3' src={userData?.sport === 'soccer' ? `./img/Admin/YearPlanner/TrainingSession/Group.png` : `./img/Admin/YearPlanner/TrainingSession/HandBallField.png`} width='170' alt="" /></Link>
                  </div>
                  <div className="col-6 col-md-8">
                  <div className='mt-3 p-2'>
  {yearPlannerFields && yearPlannerFields
    .filter((item) => item.fieldType === 'trainingSession1').length > 0 ? (
    yearPlannerFields
      .filter((item) => item.fieldType === 'trainingSession1') 
      .map((item) => (
        <div key={item._id}>
          {/* Div for the title */}
          <div className='bg-light pt-2 pb-1 px-3 rounded-0'>
            <h4>{item.title}</h4>
          </div>

          {/* Div for the notes */}
          <div className='bg-light pt-2 pb-1 px-3 rounded-0 my-2'>
            <h5>{item.notes}</h5>
          </div>

          {/* Div for the rating */}
          <p className="text-end" style={{ fontSize: '20px' }}> 
            {renderStars(item?.rating)}
          </p>
        </div>
      ))
  ) : (
    <div >
    <div className='bg-light pt-2 pb-1 px-3 rounded-0 '>
<h4>Title Here...</h4>
</div>
<div className='bg-light pt-2 pb-1 px-3 rounded-0 my-2'>
<h5>Please Create a Field...</h5>
</div>
<p className="text-end" style={{fontSize:'20px'}}> {renderStars(0)}</p>
    </div>
  )}
</div>
                  </div>
              </div>
              <div className="row ">
<div className="col-6 col-md-4">
<Link to={{
    pathname: getRedirectPath(),
    search: `?yearPlannerId=${yearPlanner?._id}&fieldType=trainingSession2`,
  }}><img className=' mt-3' src={userData?.sport === 'soccer' ? `./img/Admin/YearPlanner/TrainingSession/Group.png` : `./img/Admin/YearPlanner/TrainingSession/HandBallField.png`} width='170' alt="" /></Link>
                  </div>
                  <div className="col-6 col-md-8">
                  <div className='mt-3 p-2'>
  {yearPlannerFields && yearPlannerFields
    .filter((item) => item.fieldType === 'trainingSession2').length > 0 ? (
    yearPlannerFields
      .filter((item) => item.fieldType === 'trainingSession2') 
      .map((item) => (
        <div key={item._id}>
          {/* Div for the title */}
          <div className='bg-light pt-2 pb-1 px-3 rounded-0'>
            <h4>{item.title}</h4>
          </div>

          {/* Div for the notes */}
          <div className='bg-light pt-2 pb-1 px-3 rounded-0 my-2'>
            <h5>{item.notes}</h5>
          </div>

          {/* Div for the rating */}
          <p className="text-end" style={{ fontSize: '20px' }}> 
            {renderStars(item?.rating)}
          </p>
        </div>
      ))
  ) : (
    <div >
    <div className='bg-light pt-2 pb-1 px-3 rounded-0 '>
<h4>Title Here...</h4>
</div>
<div className='bg-light pt-2 pb-1 px-3 rounded-0 my-2'>
<h5>Please Create a Field...</h5>
</div>
<p className="text-end" style={{fontSize:'20px'}}> {renderStars(0)}</p>
    </div>
  )}
</div>
                  </div>
              </div>
              </div>
              
              <div className="col-md-5 ">
              <div className="row">
<div className="col-6 col-md-4">
<Link  to={{
    pathname: getRedirectPath(),
    search: `?yearPlannerId=${yearPlanner?._id}&fieldType=trainingSession3`,
  }}><img className=' mt-3' src={userData?.sport === 'soccer' ? `./img/Admin/YearPlanner/TrainingSession/Group.png` : `./img/Admin/YearPlanner/TrainingSession/HandBallField.png`} width='170' alt="" /></Link>
                  </div>
                  <div className="col-6 col-md-8">
                  <div className='mt-3 p-2'>
  {yearPlannerFields && yearPlannerFields
    .filter((item) => item.fieldType === 'trainingSession3').length > 0 ? (
    yearPlannerFields
      .filter((item) => item.fieldType === 'trainingSession3') 
      .map((item) => (
        <div key={item._id}>
          {/* Div for the title */}
          <div className='bg-light pt-2 pb-1 px-3 rounded-0'>
            <h4>{item.title}</h4>
          </div>

          {/* Div for the notes */}
          <div className='bg-light pt-2 pb-1 px-3 rounded-0 my-2'>
            <h5>{item.notes}</h5>
          </div>

          {/* Div for the rating */}
          <p className="text-end" style={{ fontSize: '20px' }}> 
            {renderStars(item?.rating)}
          </p>
        </div>
      ))
  ) : (
    <div >
    <div className='bg-light pt-2 pb-1 px-3 rounded-0 '>
<h4>Title Here...</h4>
</div>
<div className='bg-light pt-2 pb-1 px-3 rounded-0 my-2'>
<h5>Please Create a Field...</h5>
</div>
<p className="text-end" style={{fontSize:'20px'}}> {renderStars(0)}</p>
    </div>
  )}
</div>
                  </div>
              </div>
              <div className="row my-3">
<div className="col-6 col-md-4">
<Link to={{
    pathname: getRedirectPath(),
    search: `?yearPlannerId=${yearPlanner?._id}&fieldType=trainingSession4`,
  }}><img className=' mt-3' src={userData?.sport === 'soccer' ? `./img/Admin/YearPlanner/TrainingSession/Group.png` : `./img/Admin/YearPlanner/TrainingSession/HandBallField.png`} width='170' alt="" /></Link>
                  </div>
                  <div className="col-6 col-md-8">
                  <div className='mt-3 p-2'>
  {yearPlannerFields && yearPlannerFields
    .filter((item) => item.fieldType === 'trainingSession4').length > 0 ? (
    yearPlannerFields
      .filter((item) => item.fieldType === 'trainingSession4') 
      .map((item) => (
        <div key={item._id}>
          {/* Div for the title */}
          <div className='bg-light pt-2 pb-1 px-3 rounded-0'>
            <h4>{item.title}</h4>
          </div>

          {/* Div for the notes */}
          <div className='bg-light pt-2 pb-1 px-3 rounded-0 my-2'>
            <h5>{item.notes}</h5>
          </div>

          {/* Div for the rating */}
          <p className="text-end" style={{ fontSize: '20px' }}> 
            {renderStars(item?.rating)}
          </p>
        </div>
      ))
  ) : (
    <div >
    <div className='bg-light pt-2 pb-1 px-3 rounded-0 '>
<h4>Title Here...</h4>
</div>
<div className='bg-light pt-2 pb-1 px-3 rounded-0 my-2'>
<h5>Please Create a Field...</h5>
</div>
<p className="text-end" style={{fontSize:'20px'}}> {renderStars(0)}</p>
    </div>
  )}
</div>
                  </div>
              </div>
              {/* <h3 className='py-1 mt-4 mb-3 fw-bold'>Organization</h3>
              <div className="row">
<div className="col-6 col-md-4">
<Link to={getRedirectPath()}><img className=' mt-3' src="./img/Admin/YearPlanner/TrainingSession/Group 175.png"  alt="" /></Link>
                  </div>
                  <div className="col-6 col-md-8">
                  <div className='mt-3'>
                  <div className='bg-light pt-2 pb-1 px-3 rounded-0 '>
<h4>Heading Here...</h4>
</div>
<div className='bg-light pt-2 pb-1 px-3 rounded-0 my-2'>
<h5>Lorem ipsum dolor sit amet consectetur adipisicing elit.</h5>
</div>
<div className="container ">
<div className="mt-4 d-flex justify-content-end">
  <div className="row w-auto mt-5">
    
      <Link
        type="button"
        to='/admindashboardyearplanner'
        className="btn btn-success rounded-pill px-5 mx-3 py-2 mt-3 shadow w-100"
      >
        Done
      </Link>
    
  </div>
</div>
</div>
</div>
                  </div>
              </div> */}
              <div className="mt-4 mx-3 d-flex justify-content-end">
  <div className="row w-auto ">
    
      {userData?.role === 'admin' && <Link
        type="button"
        to='/admindashboardyearplanner'
        className="btn btn-success rounded-pill px-5 mx-3 py-2 mt-3 shadow w-100"
      >
        Done
      </Link>}
    
  </div>
</div>
              </div>
              </div>
            </div>
            {isLoading && (
        <div className="loader">
          <div></div>
        </div>
      )}
      <ToastContainer/>
          </div>
          
        </div>
      </div>
    
    </>
  )
}

export default AdminDashboardYearPlannerTrainingSession