import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import AdminSidebar from '../AdminSidebar'
import AdminHeader from '../AdminHeader'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { callApi } from '../../../Api/ApiCaller';
import routes from '../../../Api/routes';
const AdminDashboardSettingsHelpCenter = () => {
  const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const navigate = useNavigate();
    const [isloading, setIsLoading] = useState(false);
    const [subject, setSubject] = useState(""); 

    const handleNameChange = (e) => {
      setName(e.target.value);
    };
    const handleEmailChange = (e) => {
      setEmail(e.target.value);
    };
    const handleSubjectChange = (e) => {
      setSubject(e.target.value);
    };
  const handleAddHelp = (e) => {
    console.log(name); 
    console.log(email); 
    console.log(subject); 
   
    e.preventDefault();
    let getRes = (res) => {
      if (res.status == 200) {

        toast.success(res.message, {
                autoClose: 1500,
                onClose: () => {
          navigate(`/admindashboardhome`)
          }
              });
      } else {
        toast.error(res.message, {
              autoClose: 1500
              })
      }
    }; 
    let body = {
      name: name,
      email: email,
      subject: subject,
    
    };
    callApi("POST", routes.helpCenter, body, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };
  return (
    <>
    <div className="overflow-hidden">
        <div className="row">
          {/* Sidebar */}
          <AdminSidebar/>
          {/* Main Content */}
          <div className="col-md-10">
          {/* Header */}
          <AdminHeader/>
            <div className='container my-4'>
            <h2 className=''>Help Center</h2>
              <div class="d-flex align-items-center h-custom-2 px-5 ms-xl-4 mt-5 pt-5 pt-xl-0 mt-xl-n5 col-md-8 justify-content-center">
  
  <form className='set-login-width mt-md-5'>
  <div data-mdb-input-init class="form-outline mb-4">
    <label class="form-label font-600" for="form2Example18">Name</label>
    <input type="text" id="form2Example18" placeholder='Write Name...' class="form-control form-control-sm border-0 border-bottom" onChange={handleNameChange} style={{border: "2px solid #C1C1C1", borderRadius: "0"}} />
</div>
<div data-mdb-input-init class="form-outline mb-4">
    <label class="form-label font-600" for="form2Example18">Email</label>
    <input type="email" id="form2Example18" placeholder='Write Email...' class="form-control form-control-sm border-0 border-bottom" onChange={handleEmailChange} style={{border: "2px solid #C1C1C1", borderRadius: "0"}} />
</div>
<div data-mdb-input-init class="form-outline mb-4">
    <label class="form-label font-600" for="form2Example18">Subject</label>
    <input type="text" id="form2Example18" placeholder='Write Subject...' class="form-control form-control-sm border-0 border-bottom" onChange={handleSubjectChange} style={{border: "2px solid #C1C1C1", borderRadius: "0"}} />
</div>

          <div className="mb-4 mt-5 mx-auto">
                            <Link to='/admindashboardhome' type='button' onClick={handleAddHelp} className="btn btn-success rounded-pill px-5 py-2 shadow w-100">Done</Link>
                          </div>


  </form>

</div>

            </div>
            <ToastContainer/>
          </div>
          
        </div>
      </div>
    
    </>
  )
}

export default AdminDashboardSettingsHelpCenter