import React, { useEffect, useState } from 'react'
import AdminSidebar from '../Admin/AdminSidebar'
import AdminHeader from '../Admin/AdminHeader'
import { Link, useLocation } from 'react-router-dom'
import { callApi } from '../../Api/ApiCaller'
import routes from '../../Api/routes'

const CoachDashboardSquadPlayerInfo = () => {
  const [player, setPlayer] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const playerId = queryParams.get("playerId");
  const getPlayerDetail = () => {
      
    let getRes = (res) => {
      //  localStorage.setItem("userData", res?.data?.user);
      setPlayer(res.squad);
      // setproducts(res.data.data.products);
    //   console.log(productId)
    };

    callApi("GET", routes.playerDetail+'/'+playerId, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };
  useEffect(() => {
    if (playerId) {
      getPlayerDetail();
    }
  }, [playerId]);
  return (
    <>
    <div className="overflow-hidden">
        <div className="row">
          {/* Sidebar */}
          <AdminSidebar/>
          {/* Main Content */}
          <div className="col-md-10">
          {/* Header */}
          <AdminHeader/>
            <div className='container my-4'>
            <h2 className=''>Player Info</h2>
              <div class="d-flex align-items-center h-custom-2 px-5 ms-xl-4 mt-5 pt-5 pt-xl-0 mt-xl-n5 col-md-8 justify-content-center">
  
  <form className='set-login-width mt-md-3'>

<div className="text-center mb-2 mt-md-3 position-relative">
                      <img src={player?.playerImage} alt="avatar" className="rounded-circle img-fluid" width="200" style={{ width: '200px', height: '200px' }} />
                      
                  </div>
                  <div data-mdb-input-init class="form-outline mb-4">
    <label class="form-label font-600" for="form2Example18">Player Name</label>
    <input type="text" id="form2Example18" class="form-control form-control-sm border-0 border-bottom" value={player?.playerName} style={{border: "2px solid #C1C1C1", borderRadius: "0"}} />
</div>
<div data-mdb-input-init class="form-outline mb-4">
    <label class="form-label font-600" for="form2Example18">Player Position</label>
    <input type="text" id="form2Example18" class="form-control form-control-sm border-0 border-bottom" value={player?.playerPosition} style={{border: "2px solid #C1C1C1", borderRadius: "0"}} />
</div>


  </form>

</div>
            </div>
           
          </div>
          
        </div>
      </div>
    
    </>
  )
}

export default CoachDashboardSquadPlayerInfo